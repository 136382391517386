import {http} from './config';

export default {
    indexTemplate: (filter, page = 1) => {
        return http.get('api/v1/modelos?page=' + page);
    },
    update: (data) => {
        return http.put(`api/v1/propostas/${data.id}`, data);
    },
    show: (id) => {
        return http.get(`api/v1/propostas/${id}`);
    },
    listTemplate: () => {
        return http.get('api/v1/modelos/listar');
    },
    createTemplate(data) {
        return http.post('api/v1/modelos', data);
    },
    updateTemplate(data, id) {
        return http.put(`api/v1/modelos/${id}`, data);
    },
    deleteTemplate(id) {
        return http.delete(`api/v1/modelos/${id}`);
    },
    indexProposal(filter, page = 1, allotmentId = null, perpage = null) {
        if (perpage === 'selecione' || !perpage) perpage = null;
        return http.get('api/v1/propostas?page=' + page, {
            params: {...filter, perpage},
            headers: {'allotment-id': allotmentId}
        });
    },
    reprintProposal(id) {
        return http.post(`api/v1/propostas/reimprimir/${id}`);
    },
    rejectProposal(id) {
        return http.post(`api/v1/propostas/rejeitar/${id}`);
    },
    aproveProposal(id) {
        return http.post(`api/v1/propostas/aprovar/${id}`);
    },
    solicitClickSign(id) {
        return http.post(`api/v1/propostas/assinatura/${id}`);
    },
    cancelClickSign(id) {
        return http.post(`api/v1/propostas/cancelar-assinatura/${id}`);
    },
    showDocumentClickSign(id){
        return http.get(`api/v1/propostas/visualizar-assinatura/${id}`);
    },
    viewProposalAssinedManual(id){
        return http.get(`api/v1/propostas/visualizarDocumentoAssinadoManual/${id}`, {responseType: 'blob'});
    },
    deleteProposalAssinedManual(id){
        return http.delete(`api/v1/propostas/deletarDocumentoAssinadoManual/${id}`);
    },
    watsappClickSing(signer){
        return http.post(`api/v1/propostas/send-whatsapp/clicksign/signer`, signer);
    }
}