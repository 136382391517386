import {http} from './config';

export default {
    index: () => http.get('api/v1/configuracao-calculadora'),

    create: data => http.post('api/v1/configuracao-calculadora', data),

    show: id => http.get(`api/v1/configuracao-calculadora/${id}`),

    list: () => http.get('api/v1/configuracao-calculadora/list'),

    update: data => http.put(`api/v1/configuracao-calculadora/${data.id}`, data),

    delete: id => http.delete(`api/v1/configuracao-calculadora/${id}`),

    duplicate: id => http.get(`api/v1/configuracao-calculadora/duplicar/${id}`),

    getDataToBuildUrl: (propertyId, headers) => http.get(`api/v1/configuracao-calculadora/build-url/${propertyId}`, {headers}),

    listInstallmentPosition: allotmentId => http.get(allotmentId ? `api/v1/configuracao-calculadora/list-installment-position/${allotmentId}`
        : 'api/v1/configuracao-calculadora/list-installment-position'),
};