<template>
    <div>
        <div class="d-flex flex-row justify-content-end mt-5" style="width: 100vw" v-if="haveSearch &&  widthMd(370)">
            <div class="custom-search mt-2 mr-3 mb-0">
                <input type="text" class="search-query" v-model="search" placeholder="Pesquisar"
                       @keypress="emitWithEnter($event)">
                <i class="icon-search1" @click="$emit('searching', search)"></i>
            </div>
        </div>
        <div class="page-header" :class="haveSearch &&  widthMd(370) ? 'mt-0' : ''"
             style="max-width: 100vw">
            <li class="first" data-cy="page-header-title">{{ t(pageTitle) }}</li>
            <div v-if="showStatus" class="list-primary hide-text mr-5 font-weight-bold" style="width: 11rem">
                <span class="rounded-pill p-1 text-white text-center card"
                      :style="{'background-color': colorStatus, 'font-size': '13px','color': 'white'}">
                                 {{ showStatus }}
                </span>
            </div>
            <div class="row justify-content-end">
                <div class="col-12 justify-content-end pb-1 pr-0 mr-0"
                     v-if="haveSearch && widthMd(500) && widthMd(370, '>')">
                    <div class="custom-search mr-0">
                        <input type="text" class="search-query pr-0" v-model="search" placeholder="Pesquisar"
                               @keypress="emitWithEnter($event)">
                        <i class="icon-search1" @click="$emit('searching', search)"></i>
                    </div>
                </div>
                <div class="custom-search" v-if="haveSearch && !widthMd(500)">
                    <input type="text" class="search-query" v-model="search" placeholder="Pesquisar"
                           @keypress="emitWithEnter($event)">
                    <i class="icon-search1" @click="$emit('searching', search)"></i>
                </div>
                <a class="btn btn-primary" id="filter" data-toggle="modal" data-target="#customModalTwo"
                   @click="$emit('filter')" v-if="filter">
                    Filtro
                </a>
                <a class="btn btn-primary" @click="$emit('copyLink')" v-if="copyLink" id="copy">
                    <i class="icon-copy"></i> {{ t(copyLink) }}
                </a>
                <div class="dropdown" v-if="dropdown">
                    <button class="btn btn-custom bg-btn ml-3 dropdown-toggle" type="button" id="dropdownMenu2"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Opções
                    </button>
                    <div class="dropdown-menu dropdown-menu-right w-auto" aria-labelledby="dropdownMenu2">
                        <button v-for="(iten, key) in dropdown" :key="key" class="dropdown-item" type="button"
                                :data-toggle="iten.modal ? 'modal' : ''"
                                :data-target="iten.modal ?'#customModalTwo' : ''"
                                @click="iten.emit ? $emit(iten.emit) : ''">
                            <i :class="iten.icon"></i> {{ t(iten.title) }}
                        </button>
                    </div>
                </div>
                <a class="btn btn-custom bg-btn ml-3" data-toggle="modal" data-target="#customModalTwo"
                   v-if="btnNew"
                   @click="$emit('newType')" id="new" data-cy="btn-new-page-header">
                    <i :class="iconBtnNew ? iconBtnNew : 'icon-circle-with-plus'"></i> {{ t(btnNew) }}
                </a>

                <div v-if="btnZoom">
                    <div class="gantt_control">
                        <div class="dropdown">
                            <button class="dropbtn">Exportar</button>
                            <div class="dropdown-content">
                                <a href="#" @click="this.$emit('exportToPDF')">Exportar para PDF</a>
                                <a href="#" @click="this.$emit('exportToPNG')">Exportar para PNG</a>
                                <a href="#" @click="this.$emit('exportToPNGMeadow')">Exportar :: Meadow</a>
                                <a href="#" @click="this.$emit('exportToPDFBroadway')">Exportar :: Broadway</a>
                                <a href="#" @click="this.$emit('exportToPDFSkyblue')">Exportar :: Skyblue</a>
                                <a href="#" @click="this.$emit('exportToPNGMaterial')">Exportar :: Material</a>
                            </div>
                        </div>
                    <button class="btn btn-primary" @click="this.$emit('toggleMode', $event)" style="margin: 0 5px 3px 0 ;">
                        <i class="fas fa-recycle"></i>
                    </button>
                    <button class="btn btn-primary" @click="this.$emit('zoom_in')" style="margin: 0 5px 3px 0 ;">
                        <i class="fas fa-search-plus"></i>
                    </button>
                    <button class="btn btn-primary" @click="this.$emit('zoom_out')" style="margin: 0 5px 3px 0 ;">
                        <i class="fas fa-search-minus"></i>
                    </button>
                </div>
                </div>

                <a class="btn btn-custom bg-btn ml-3" data-toggle="modal" data-target="#customModalTwo"
                   @click="$emit('secondAction')" v-if="secondBtn" id="secondbtn">
                    <i :class="secondIconBtnNew ? secondIconBtnNew : 'icon-circle-with-plus'"/> {{ t(secondBtn) }}
                </a>
                <div v-if="dropdownClient">
                    <div class="text-right ml-3">
                        <button data-cy="lot_index_dropdown" class="btn btn-custom bg-btn no-border" type="button"
                                id="import-buttom"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="icon-menu"/>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-black dropdown-lot w-auto pr-2"
                             id="dropdown-import">
                            <div class="dropdown-item bg-hover-black" @click="this.$emit('uploadFileClient')">
                                <div class="row item-custom2 ml-1 text-white ">
                                    <i class="icon-folder_open mr-2 font-15"/>{{ t('CLIENTS.IMPORT_FILE') }} XLSX
                                </div>
                            </div>
                            <div class="dropdown-item bg-hover-black">
                                <a class="row item-custom2 ml-1 text-white" @click="this.$emit('exportClient')">
                                    <i class="icon-file mr-2 font-15"/>{{ t('CLIENTS.EXPORT_FILE_CLIENT') }} XLSX
                                </a>
                            </div>

                            <div class="dropdown-item bg-hover-black">
                                <a class="row item-custom2 ml-1 text-white" href="Modelo para Importação Pessoas.xlsx"
                                   download>
                                    <i class="icon-arrow-with-circle-down mr-2 font-15"/>{{
                                        t('GENERAL.MSG.DOWNLOAD_FILE_EXEMPLE')
                                    }}
                                </a>
                            </div>

                          <div class="dropdown-item bg-hover-black">
                            <a class="row item-custom2 ml-1 text-white" @click="$router.push('/clientes/dashboard')">
                              <i class="icon-show_chart mr-2 font-15"/>{{t('GENERAL.MSG.DASHBOARD_CLIENTS')}}
                            </a>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {useI18n} from "vue-i18n";

export default {
    props: {
        pageTitle: {
            type: String,
            required: true
        },
        btnNew: {
            type: String,
            required: false
        },
        secondBtn: {
            type: String,
            required: false
        },
        iconBtnNew: {
            type: String,
            default: ''
        },
        secondIconBtnNew: {
            type: String,
            default: ''
        },
        dropdown: {
            type: Object,
            required: false
        },
        dropdownClient: {
            type: Boolean,
            required: false
        },
        copyLink: {
            type: String,
            required: false
        },
        filter: {
            type: Boolean,
            default: false
        },
        haveSearch: {
            type: Boolean,
            default: false
        },
        showStatus: {
            type: String,
            default: '',
        },
        colorStatus: {
            type: String,
            default: '',
        },
        haveZoom: {
            type: Boolean,
            default: false
        },
        btnZoom: {
            type: Boolean,
            default: false
        }
    },
    emits: ['newType', 'filter', 'searching', 'copyLink', 'uploadFileClient', 'toggleMode', 'zoom_out', 'zoom_in',
    'exportToPDF','exportToPNG','exportToPNGMeadow','exportToPDFBroadway','exportToPDFSkyblue','exportToPNGMaterial'],
    setup() {
        const {t} = useI18n();
        return {t,}
    },
    data() {
        return {
            search: null,
        }
    },
    methods: {
        emitWithEnter(log) {
            if (log.key == 'Enter') {
                this.$emit('searching', this.search);
            }
        },
        widthMd(size = 1025, smaller = '<') {
            if (smaller == '<') {
                return window.innerWidth < size;
            } else {
                return window.innerWidth > size;
            }
        },
    }
}
</script>

<style>
/* Estilo do botão dropdown de Exportar*/

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropbtn:focus {
    outline: none;
}
/* FIM Estilo do botão dropdown de Exportar*/

.first {
    border-radius: 0px;
    background: transparent;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 0;
    font-size: 1.5rem;
    align-items: center;
}

.btn-custom {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
}

.bg-btn {
    background-color: white !important;
    color: black !important;
    border: 1px solid #0e5e4e;
}

.bg-btn:hover, .bg-btn:focus {
    background-color: #0e5e4e !important;
    color: white !important;
}

.search-query::placeholder {
    color: #0e5e4e;
    opacity: 0.9
}
</style>
