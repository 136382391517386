<template>
    <form @submit.prevent="updateTemplate">
        <div class="modal-row2 modal-height-max" id="myTabContent">
            <div class="tab-pane" id="template">
                <div class="modal-body">
                    <div class="row mt-negative-1">
                        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4 overflow labels-container"
                             style="overflow-x: hidden; overflow-y: auto">
                            <div class="tags-block">
                                <h5>Tags</h5>
                                <div class="lablesContainerScroll">
                                    <div class="tags" v-for="(category, key) in tags.categories" :key="key">
                                        <a href="#" @click="tags.items[key].open = !tags.items[key].open">
                                            <i class="icon-label" :class="colorTag(key)"></i> {{ category }}
                                            <i class="icon-expand_more" v-if="!tags.items[key].open"></i>
                                            <i class="icon-expand_less font-weight-bold"
                                               v-if="tags.items[key].open"></i>
                                        </a>
                                        <div v-show="tags.items[key].open">
                                            <div class="d-flex flex-row container align-items-start flex-wrap">
                                                <div v-for="category in tags.items[key]" :key="category.id">
                                                    <div class="badge badge-pill badge-light mb-1 mt-1 mr-1"
                                                         style="font-size: 0.73rem; white-space: normal; cursor: pointer"
                                                         @click="copyText(`${key}${category.name_tag}`, category.traduction_tag)"
                                                         :id="`${key}${category.name_tag}`">{{ category.text }}
                                                        <i title="copiar" class="icon-copy"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-10 col-lg-8 col-md-8 col-sm-8 col-8 mt-3">
                            <div class="row gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label for="name" class="required">{{
                                                t('PROPOSAL.TEMPLATE_NAME')
                                            }}: </label>
                                        <input type="text" id="name" name="name" v-model="proposal.name"
                                               class="form-control"
                                               placeholder="Nome">
                                        <p class="validation" v-if="requiredName">
                                            {{ t('GENERAL.MSG.REQUIRED_FIELD') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label class="required">{{ t('PROPOSAL.TEMPLATE') }}: </label>
                                        <editor :init="configTinyMce"
                                                v-model="proposal.text"
                                                tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js"></editor>
                                        <p class="validation" v-if="requiredText">
                                            {{ t('GENERAL.MSG.REQUIRED_FIELD') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="justify-content: space-between">
            <div>
                <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
            </div>
            <div class="tags-block mb-2 ml-2 row left-content justify-content-between paddin">
                <div class="d-flex flex-row align-items-center">
                    <div class="tags" v-for="sub in subtitles" :key="sub.id">
                        <div class="mr-2">
                            {{ `Tipo ${sub.position}: ${sub.label}` }}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button data-cy="client_add_close" class="btn btn-secondary mr-2" data-dismiss="modal"
                        type="button">
                    {{ t('ACTIONS.CLOSE') }}
                </button>
                <button data-cy="client_add_save" class="btn btn-primary" type="submit">
                    {{ t('ACTIONS.SAVE') }}
                </button>
            </div>
        </div>
    </form>
</template>

<script>

import {useI18n} from 'vue-i18n';
import Tags from '../../services/Tags';
import Editor from '@tinymce/tinymce-vue';
import {useToast} from 'vue-toastification';
import Proposal from '../../services/Proposal';
import SimulatorConfig from '../../services/SimulatorConfig';

export default {
    name: 'createTemplateProposalComponent',
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },
    components: {
        Editor,
    },
    props: {
        itenIndex: {
            type: Object,
            required: true,
        },
    },
    watch: {
        itenIndex: function () {
            this.proposal = this.itenIndex;
            this.listTags();
            this.listLegends();
        },
    },
    data() {
        return {
            configTinyMce: {
                license_key: 'gpl',
                selector: 'textarea#open-source-plugins',
                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                imagetools_cors_hosts: ['picsum.photos'],
                menubar: 'file edit view insert format tools table help',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                toolbar_sticky: true,
                autosave_ask_before_unload: true,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                image_advtab: true,
                importcss_append: true,
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 600,
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image imagetools table',
                skin: 'oxide',
                content_css: 'default',
                language: 'pt_BR',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            },
            proposal: {},
            requiredName: false,
            requiredText: false,
            tags: {items: [], categories: []},
            subtitles: [],
        };
    },
    mounted() {
        this.proposal = this.itenIndex;
        this.listTags();
        this.listLegends();
    },
    methods: {
        copyText(id, textTag) {
            navigator.clipboard.writeText(textTag);
        },
        colorTag(key) {
            if (key > 6) key = (key % 6) - 1;
            if (key === 0) return 'text-primary';
            if (key === 1) return 'text-secondary';
            if (key === 2) return 'text-info';
            if (key === 3) return 'text-warning';
            if (key === 4) return 'text-success';
            if (key === 5) return 'text-danger';
            if (key === 6) return 'text-success';
            if (key) return 'text-primary';
        },
        listTags() {
            this.$store.commit('changeLoading', true);
            Tags.list().then(resp => {
                resp.data.forEach(item => {
                    if (!this.tags.categories.includes(item.category)) {
                        this.tags.categories.push(item.category);
                    }
                });
                this.tags.categories.forEach((category, key) => {
                    this.tags.items[key] = [];
                    resp.data.forEach(item => {
                        if (item.category === category) {
                            this.tags.items[key].push(item);
                        }
                    });
                });
                setTimeout(() => {
                    this.$store.commit('changeLoading', false);
                }, 1500); // dar tempo do editor carregar o template
            }).catch(() => this.$store.commit('changeLoading', false));
        },
        listLegends() {
            const allotmentId = this.proposal?.allotments[0]?.id ?? null;
            this.$store.commit('changeLoading', true);

            SimulatorConfig.listInstallmentPosition(allotmentId)
                .then(({data}) => this.subtitles = data)
                .catch(err => this.errorMsg(err))
                .finally(() => this.$store.commit('changeLoading', false));
        },
        updateTemplate() {
            Proposal.updateTemplate(this.proposal, this.proposal.id).then(() => {
                this.toast.success(this.t('PROPOSAL.UPDATED_TEMPLATE'));
                this.$emit('updated');
                document.getElementById('closeX').click();
            }).catch(err => this.errorMsg(err)).finally(() => this.$store.commit('changeLoading', false));
        },
        errorMsg(error) {
            const erro = error.response.data.validations.errors;

            if (erro.sql || !erro) {
                this.toast.error(error.response.data.validations.message, {
                    timeout: false,
                });
            } else {
                for (let column in erro) {
                    for (let msg in erro[column]) {
                        this.toast.error(erro[column][msg], {timeout: false});
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.mt-negative-1 {
    margin-top: -1.2%;
}

div::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
}
</style>